<template>
	<div style="width: 100%">
		<div class="clearfix">
			<a-upload :remove="handleRemove" :before-upload="beforeUpload">
				<a-button>
					<a-icon type="upload" />
					上传
				</a-button>
			</a-upload>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			file: null,
			uploading: false
		};
	},
	props: {
		url: {
			type: String,
			default: () => {
				return '';
			}
		},
		fileId: {
			type: String | Number
		},
		shareId: {
			type: String | Number
		}
	},

	methods: {
		handleRemove(file) {
			this.file = '';
		},
		//文件上传之前
		beforeUpload(file) {
			this.file = file;
			return false;
		},
		// 上传
		handleUpload() {
			const formData = new FormData();
			formData.append('file', this.file);
			formData.append('fileId', this.fileId);
			if (this.shareId) {
				formData.append('shareId', this.shareId);
			}
			this.uploading = true;
			return this.http.post('/platform' + this.url, formData);
		}
	}
};
</script>

<style scoped></style>
