<template>
	<div>
		<a-form ref="formRef" :form="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="7" v-if="getOrganId() == 0">
					<a-form-model-item label="所属单位" label-position="top" prop="organId">
						<a-select @change="changeOrganId" v-model="formData.organId" style="width:100%" placeholder="请选择单位">
							<a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
								organ.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="7">
					<a-form-model-item label="请选择分类" label-position="top">
						<a-select v-model="formData.categoryId" style="width:100%" placeholder="请选择分类">
							<a-select-option :value="category.categoryId" v-for="category in categoryList"
								:key="category.categoryId">{{ category.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label="请输入共享空间名称" label-position="top">
						<a-input v-model="formData.name"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="7">
					<a-form-model-item label="请选择创建权限范围" label-position="top">
						<select-staff :value="formData.createScope" @input="e => (formData.createScope = e)"
							multiple></select-staff>
					</a-form-model-item>
				</a-col>
				<a-col span="7">
					<a-form-model-item label="请选择下载权限范围" label-position="top">
						<select-staff :value="formData.downScope" @input="e => (formData.downScope = e)"
							multiple></select-staff>
					</a-form-model-item>
				</a-col>
				<a-col span="7">
					<a-form-model-item label="请选编辑权限范围" label-position="top">
						<select-staff :value="formData.editScope" @input="e => (formData.editScope = e)"
							multiple></select-staff>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="7">
					<a-form-model-item label="请选择管理权限范围" label-position="top">
						<select-staff :value="formData.managerScope" @input="e => (formData.managerScope = e)"
							multiple></select-staff>
					</a-form-model-item>
				</a-col>
				<!-- <a-col span="4">
					<a-form-model-item label="是否可以复制" label-position="top">
						<a-switch v-model="formData.isCopy"></a-switch>
					</a-form-model-item>
				</a-col> -->
				<a-col span="4">
					<a-form-model-item label="是否可以下载" label-position="top">
						<a-switch v-model="formData.isDown"></a-switch>
					</a-form-model-item>
				</a-col>
				<a-col span="3">
					<a-form-model-item label="是否可以打印" label-position="top">
						<a-switch v-model="formData.isPrint"></a-switch>
					</a-form-model-item>
				</a-col>
				<a-col span="3">
					<a-form-model-item label="是否可以阅读" label-position="top">
						<a-switch v-model="formData.isRead"></a-switch>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="6">
					<a-form-model-item label="请选择开放范围" label-position="top">
						<a-select v-model="formData.openScope" placeholder="请选择开发范围">
							<a-select-option :value="openScope" v-for="(openScope, index) in openScopeList" :key="index">{{
								openScope }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="6" v-if="formData.openScope == '指定部门'">
					<a-form-model-item label="请选择可以访问的部门" label-position="top">
						<select-depart :value="formData.openDepIds" :organId="formData.organId" multiple
							@change="e => (formData.openDepIds = e)"></select-depart>
					</a-form-model-item>
				</a-col>
				<a-col span="6" v-if="formData.openScope == '指定人员'">
					<a-form-model-item label="可访问的人员" label-position="top">
						<select-staff :value="formData.openStaffIds" @input="e => (formData.openStaffIds = e)"
							multiple></select-staff>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="7">
					<a-form-model-item label="阅读权限范围" label-position="top">
						<select-staff :value="formData.readScope" @input="e => (formData.readScope = e)"
							multiple></select-staff>
					</a-form-model-item>
				</a-col>
				<a-col span="7">
					<a-form-model-item label="容量" label-position="top">
						<a-input type="number" v-model="formData.spaceSize" aria-placeholder="请输入容量"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="15">
					<a-button type="primary" @click="onSubmit">
						确认保存
					</a-button>
					<a-button style="margin-left:20px" @click="$emit('add')" icon="rollback">返回</a-button>
				</a-col>
			</a-row>
		</a-form>
	</div>
</template>

<script>
import selectStaff from '@/components/common/select-staff.vue';
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import selectDepart from '@/components/common/select-depart.vue';

export default {
	components: { selectStaff, selectDepart },
	data() {
		return {
			organList: apiUtil.getOrganList(),
			categoryList: [],
			openScopeList: ['全体', '指定部门', '指定人员'],
			formData: {
				organId: apiUtil.getOrganId(''),
				categoryId: '请选择分类',
				createScope: [],
				downScope: [],
				editScope: [],
				isCopy: true,
				isDelete: true,
				isDown: true,
				isPrint: true,
				isRead: true,
				managerScope: [],
				name: '',
				openDepIds: [],
				openScope: '',
				openStaffIds: [],
				readScope: [],
				spaceSize: 0
			},
			formRule: {}
		};
	},
	mounted() {
		if (this.isEdit) {
			this.formData = this.sharedata;
			console.log(this.sharedata);
		}
		this.getcategoryList();
	},
	props: ['isEdit', 'sharedata'],
	methods: {
		...apiUtil,
		onSubmit() {
			this.formData.createScope.push(0);
			this.formData.managerScope.push(0);
			this.http
				.post('/platform/scope/createShare', this.formData)
				.then(ret => {
					if (ret.code == 200) {
						utils.success('操作成功！').then(() => {
							this.$emit('add');
						});
					}
				})
				.catch(err => { });
		},
		// 获取分类
		getcategoryList() {
			this.http
				.get('/platform/scope/category', { organId: this.formData.organId })
				.then(ret => {
					this.categoryList = ret.data;
				})
				.catch(err => { });
		},
		changeOrganId() {
			this.getcategoryList();
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}

.avatar-uploader>.ant-upload {
	width: 128px;
	height: 128px;
}

.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}
</style>
