<template>
	<div>
		<div v-if="isTable">
			<a-layout>
				<div class="content-header">
					<a-row type="flex" justify="space-between">
						<a-col style="margin-bottom: 20px" span="10">
							<a-button-group>
								<a-button v-if="isShow" @click="isTable = false" type="primary">创建共享空间</a-button>
								<a-button v-if="!isShow && isCode" v-has="'space:share:create-folder'"
									@click="$refs.btable.addfolder()" type="primary">添加文件夹</a-button>
								<a-button v-if="!isShow && isCode" v-has="'space:share:upload'"
									@click="$refs.btable.addfile()" type="primary">添加文件</a-button>
							</a-button-group>
						</a-col>

						<a-col span="4" v-if="isShow">
							<a-input v-model="param.keyword" allowClear search @pressEnter="getShareList"
								@on-clear="getShareList" placeholder="关键词查询"></a-input>
						</a-col>
						<a-col span="10" v-if="!isShow">
							<a-row type="flex" justify="end" :gutter="3">
								<a-col>
									<a-input v-model="keyword" allowClear search @pressEnter="$refs.btable.getfilesList()"
										@on-clear="$refs.btable.getfilesList()" placeholder="关键词查询"></a-input>
								</a-col>
								<a-col v-if="!isShow">
									<a-button @click="$refs.btable.black()">上一级</a-button>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<div style="font-weight: 600">{{ `文件路径：${path}` }}</div>
						</a-col>
					</a-row>
					<hr />
				</div>
			</a-layout>

			<div v-if="isShow">
				<a-row :gutter="[16, 16]" type="flex" justify="start">
					<a-col v-for="share in data.records" :key="share.shareId" @click="enter(share)">
						<a-card style="width: 300px">
							<template slot="title">
								<div>{{ share.name }}</div>
							</template>
							<div style="text-align: center; margin-bottom: 5px">
								<!-- <a-icon style="font-size:60px;color:rgb(24,144,255);" type="codepen-square" theme="filled" /> -->
								<a-icon style="font-size: 60px; color: rgb(24, 144, 255)" type="folder-open"
									theme="filled" />
							</div>
							<p style="text-align: center">创建时间：{{ share.createTime }}</p>
						</a-card>
					</a-col>
				</a-row>
				<a-row>
					<a-col span="12" offset="6" style="text-align: center; padding: 20px 0">
						<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
							@showSizeChange="setLimit" :current="data.current" />
					</a-col>
				</a-row>
			</div>

			<b-table ref="btable" :keyword="keyword" :shareId="shareId" @enter="entertable" @changeShow="changeShow"
				@success="e => (isCode = e)" @black="black" v-if="!isShow"></b-table>
			<add-form ref="addForm" @success="doRefresh" @changeShow="changeShow"></add-form>
		</div>

		<setting-form v-else @add="add" ref="settingForm" @success="doRefresh"></setting-form>
	</div>
</template>

<script>
import settingForm from '../setting/components/settingForm.vue';
import bTable from './components/table.vue';
import addForm from './components/addForm.vue';
import utils from '@/common/utils';
export default {
	components: { bTable, addForm, settingForm },
	data() {
		return {
			isCode: false,
			isShow: true,
			keyword: '',
			isTable: true,
			shareId: '',
			data: {},
			path: '/',
			pathArr: [],
			pageSize: 10,
			total: '',
			param: {
				page: 1,
				limit: 20,
				keyword: '',
				organId: ''
			}
		};
	},
	mounted() {
		this.getShareList();
	},
	methods: {
		// 获取共享空间
		getShareList() {
			utils.showSpin();
			this.http
				.get('/platform/scope/share', this.param)
				.then(ret => {
					this.data = ret.data;
					// console.log(ret.data.records);
					this.total = ret.data.total;
					utils.closeSpin();
				})
				.catch(err => {
					utils.closeSpin();
				});
		},
		black(path) {
			console.log(path);
			const index = this.pathArr.findIndex(item => item == path);
			this.pathArr.splice(index, 1);
			this.path = this.pathArr.join('>');
		},

		// 表格内部进入触发
		entertable(e) {
			this.keyword = '';
			this.pathArr.push(e);
			// console.log(this.pathArr);
			this.path = this.pathArr.join('>');
		},
		doRefresh() {
			this.getShareList();
		},
		// 返回到最顶层
		changeShow() {
			this.path = '/';
			this.pathArr = [];
			this.isShow = !this.isShow;
		},
		enter(share) {
			// console.log(11111);
			this.shareId = share.shareId;
			this.pathArr.push(share.name);
			this.path = this.pathArr.join('>');
			this.isShow = false;
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getShareList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getShareList();
		},
		// 添加完成以后
		add() {
			this.isTable = true;
			this.getShareList();
		}
	}
};
</script>

<style scoped lang="less"></style>
