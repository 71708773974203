<template>
	<div>
		<div>
			<a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false"
				:indentSize="25" :style="{ background: '#FFF' }" size="small" row-key="fileId" border ref="list"
				:loading="loading" :data-source="data.records" :customRow="customRow">
				<!-- 文件类型 -->
				<template slot-scope="row" slot="type">
					<a-button type="link">
						<a-icon v-if="row.type == '目录'" type="folder" theme="filled" />
						<a-icon v-if="row.type == '文件' && row.suffix == 'word'" type="file-word" theme="filled" />
						<a-icon v-if="row.type == '文件' && (row.suffix == 'png' || 'img')" type="file-image"
							theme="filled" />
						<a-icon v-else-if="row.type == '文件'" type="file-unknown" theme="filled" />
					</a-button>
				</template>
				<!-- 大小 -->
				<template slot-scope="row" slot="size">
					<div v-if="row.size < 1000">{{ row.size.toFixed(2) }}K</div>
					<div v-if="row.size >= 1000">{{ (row.size / 1000).toFixed(2) }}M</div>
				</template>
				<!--操作-->
				<template slot-scope="row" slot="action">
					<a-tooltip v-if="row.type == '文件' && utils.isWordOrExcelFile(row.name) && code.isRead" title="预览">
						<a-button @click="$refs.office.show(row.url, 0)" type="link" icon="eye"></a-button>
					</a-tooltip>
					<a-tooltip v-if="row.type == '文件' && utils.isImageFile(row.name) && code.isRead" title="预览">
						<a-button @click="utils.openImage(row.url)" type="link" icon="eye"></a-button>
					</a-tooltip>
					<a-tooltip v-if="row.type == '文件' && utils.isWordOrExcelFile(row.name) && code.isEdit" title="编辑">
						<a-button @click="$refs.office.show(row.url, 1)" type="link" icon="edit"></a-button>
					</a-tooltip>
					<a-button-group shape="circle" size="large">
						<a-tooltip v-if="row.type == '文件' && code.isDown" title="下载">
							<a-button @click="down(row)" type="link" icon="download"></a-button>
						</a-tooltip>
						<a-tooltip title="删除">
							<a-button v-if="code.isManager" type="link" icon="delete" @click="del(row.fileId)"></a-button>
						</a-tooltip>
					</a-button-group>
				</template>
			</a-table>
			<a-row>
				<a-col span="12" offset="6" style="text-align: center; padding: 20px 0">
					<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
						@showSizeChange="setLimit" :current="data.current" />
				</a-col>
			</a-row>
		</div>
		<add-form ref="addForm" @success="doRefresh"></add-form>
		<office ref="office"></office>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import addForm from './addForm.vue';
import utils from '@/common/utils';
import office from '@/components/office/document';
var FileSaver = require('file-saver');
export default {
	components: { addForm, office },
	data() {
		return {
			data: {},
			code: {},
			loading: false,
			organList: apiUtil.getOrganList(),
			pageSizeOptions: ['10', '20', '30', '40', '50'],
			pageSize: 10,
			fileId: null,
			total: '',
			id: '',
			Documentdata: [],
			parentId: '',
			// 文件的
			idfs: {
				shareId: '',
				fileId: ''
			},
			// 文件夹的
			ids: {
				shareId: '',
				parentId: ''
			},
			param: {
				current: 1,
				limit: 20,
				keyword: ''
			},
			columns: [
				{ title: '', width: 80, align: 'center', scopedSlots: { customRender: 'type' } },
				{ title: '文件名称', dataIndex: 'name' },
				{ title: '创建时间', dataIndex: 'createTime' },

				{ title: '大小', scopedSlots: { customRender: 'size' } },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
			]
		};
	},
	props: ['shareId', 'changeShow', 'keyword'],
	mounted() {
		this.ids.shareId = this.shareId;
		this.idfs.shareId = this.shareId;
		this.getCode();
		this.getfilesList();
	},
	watch: {
		param(newv, oldv) {
			console.log(newv);
		}
	},
	methods: {
		// 获取文件列表
		getfilesList(fileId = this.fileId) {
			this.loading = true;
			this.param.keyword = this.keyword;
			this.http
				.get('/platform/scope/share/files', { shareId: this.shareId, fileId: fileId, ...this.param })
				.then(ret => {
					this.data = ret.data;
					if (this.data.records) {
						this.parentId = this.data.records[0].parentId;
					}
					this.total = data.total;
					console.log(ret.data);
					this.param.current = data.current;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		// 获取共享空间的权限
		getCode() {
			this.loading = true;
			this.http
				.get('/platform/scope/share/auth', { shareId: this.shareId })
				.then(ret => {
					console.log(ret);
					this.code = ret.data;
					this.$emit('success', this.code.isCreate);
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		// 删除文件夹
		del(id) {
			const ids = [];
			ids.push(id);
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.post('/platform/scope/share/del', { ids: ids }).then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getfilesList();
						});
					}
				});
			});
		},
		// 下载
		down(row) {
			// console.log(row); return;
			FileSaver.saveAs(row.url, row.name);
			// this.http.download(`/platform/scope/download/${row.code}`, {}, row.name);
		},
		// 返回上一级
		black() {
			const len = this.Documentdata.length;
			if (this.Documentdata.length < 1) {
				this.$emit('changeShow');
			} else {
				this.$emit('black', this.Documentdata[len - 1].name);
				this.getfilesList(this.Documentdata[len - 1].parentId);
				this.Documentdata.pop(1);
				if (this.Documentdata.length == 0) {
					this.ids.parentId = '';
					this.idfs.fileId = '';
				} else {
					const id = this.Documentdata[this.Documentdata.length - 1].fileId;
					this.ids.parentId = id;
					this.idfs.fileId = id;
				}
			}
		},
		addfolder() {
			this.$refs.addForm._show(this.ids, '文件夹');
		},
		addfile() {
			this.$refs.addForm._show(this.idfs, '文件');
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getfilesList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getfilesList();
		},
		doRefresh() {
			console.log('成功成功');
			this.getfilesList();
		},
		customRow(record, index) {
			if (record.type == '目录') {
				return {
					on: {
						dblclick: () => {
							this.Documentdata.push(record);
							this.fileId = record.fileId;
							this.idfs.fileId = record.fileId;
							this.ids.parentId = record.fileId;
							this.$emit('enter', record.name);
							this.getfilesList();
						}
					}
				};
			}
		}
	}
};
</script>

<style></style>
