<template>
	<a-modal v-model="show" :title="`添加${type}`" :width="400" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model v-if="isfile" ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="24">
					<a-form-model-item label-position="top" prop="name">
						<a-input v-model="formData.name" placeholder="请输入文件夹名称"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>
		<a-form-model v-else>
			<a-row :gutter="15">
				<a-col span="24">
					<a-form-model-item label-position="top" prop="name">
						<upfile ref="upfile" url="/scope/share/upload" :shareId="fileData.shareId"
							:fileId="fileData.fileId"></upfile>
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>
	</a-modal>
</template>

<script>
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';

import upfile from '../../components/upFile.vue';
export default {
	components: { upfile },
	data() {
		return {
			aaa: '',
			isfile: true,
			show: false,
			loading: false,
			organList: apiUtil.getOrganList(),
			staffList: [],
			formData: {
				name: '',
				parentId: '',
				shareId: ''
			},
			type: '',
			// 上传文件的表单
			fileData: {
				file: null,
				fileId: '',
				shareId: ''
			},
			formRule: {
				name: [{ required: true, message: '请输入文件夹名称' }]
			}
		};
	},
	methods: {
		ddd() {
			// console.log('111');
		},
		...apiUtil,
		_show(form, type) {
			this.show = true;
			this.type = type;
			if (type == '文件') {
				this.isfile = false;
			} else if (type == '文件夹') {
				this.isfile = true;
			}
			this.formData.name = '';
			this.$nextTick(() => {
				if (form) {
					const formData = JSON.parse(JSON.stringify(form));
					this.formData.parentId = formData.parentId;
					this.formData.shareId = formData.shareId;
					if (formData.fileId) {
						this.fileData.fileId = formData.fileId;
					} else {
						this.fileData.fileId = 0;
					}
					this.fileData.shareId = formData.shareId;
					// console.log(formData);
				} else {
					this.formData.name = '';
				}
			});
		},
		_close() {
			// this.$refs.formRef.resetFields();
			this.show = false;
			this.formData.name = '';
			this.fileData.name = '';
			this.$emit('close');
		},
		doSubmit() {
			utils.showSpin();
			console.log(this.formData);
			if (this.type == '文件夹') {
				this.http.post('/platform/scope/share/create-folder', this.formData).then(ret => {
					if (ret.code == 200) {
						utils.success('操作成功！').then(() => {
							this.show = false;
							this.$emit('success');
						});
						this.show = false;
					}
				});
			}
			if (this.type == '文件') {
				this.$refs.upfile.handleUpload().then(ret => {
					if (ret.code == 200) {
						utils.success('操作成功！').then(() => {
							this.show = false;
							this.$emit('success');
						});
					}
				});
			}
		}
	},
	watch: {
		type(newV) {
			console.log('改变了', newV);
		}
	}
};
</script>

<style scoped></style>
